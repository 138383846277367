<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <a href="/schools">Schools</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <a href="/schools/edit">Edit College Data</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="table !== ''">
          <i class="fas fa-table"></i>
          {{ table }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h2 class="text-success mb-3">Edit College Data</h2>
    <hr />
    <!-- <el-dialog title="新增欄位" :visible.sync="addTag" width="50%">
      <div
        v-for="(item, index) in inputs"
        :key="index"
        class="row"
        style="margin:0 0 20px 0"
      >
        <div class="col-sm-10" style="padding:0">
          <el-input
            v-model="inputs[index]"
            placeholder="請輸入欄位名稱"
            @change="verifyName(index)"
          ></el-input>
        </div>
        <div class="col-sm-2 text-center" style="line-height:40px;">
          <el-button type="danger" size="small" @click="removeInput(index)">
            <i class="fa fa-trash"></i>
          </el-button>
        </div>
      </div>
      <div style="margin-top:20px" class="text-center">
        <el-button style="width:100%" type="success" @click="addInput">
          <i class="fa fa-plus"></i>
        </el-button>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addTag = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >新 增</el-button
        >
      </span>
    </el-dialog> -->
    <el-tabs
      type="border-card"
      v-loading="loading"
      element-loading-text="正在上傳文件並更新資料，請稍後。"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-tab-pane>
        <span slot="label">
          <i class="fa fa-download"></i>
          下載需要修改的資料文件
        </span>
        <div>
          <el-form label-width="100px">
            <el-form-item label="選擇數據表">
              <el-select
                style="width:100%"
                v-model="tableList"
                filterable
                placeholder="選擇需要修改的數據表"
                @change="selectTable"
              >
                <el-option
                  v-for="item in tables"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="tags.length > 0">
              <span slot="label">
                選擇欄位
                <!-- <el-button type="text" class="text-success" @click="addTag = true"
                  ><i class="fa fa-plus"></i
                ></el-button> -->
              </span>
              <el-select
                style="width:100%"
                v-model="checkedTags"
                filterable
                multiple
                placeholder="選擇欄位"
              >
                <el-option
                  v-for="item in tags"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="資料下載" v-if="checkedTags.length > 0">
              <el-button type="success" @click="getTableFile">
                <i class="fa fa-download"></i>下载資料文件</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label">
          <i class="fa fa-upload"></i>
          上傳修改後的資料文件
        </span>
        <div>
          <el-upload
            class="upload-demo"
            drag
            :action="postUrl"
            :headers="token"
            name="excel"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <div>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                將大學資料文件拖到此處，或<em>點擊上傳</em>
              </div>
            </div>
          </el-upload>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import JwtService from "@/common/jwt.service";
import schoolApi from "@/views/schools/apis/school.js";

export default {
  metaInfo() {
    return {
      title: `Edit College Data ${this.table} - Ivy-Way Academy`
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      token: { Authorization: `Bearer ${JwtService.getToken()}` },
      addTag: false,
      tags: [],
      checkedTags: [],
      inputs: [],
      loading: false,
      tableList: "",
      tables: [
        "academics",
        "admissions",
        "campus",
        "moneys",
        "schools",
        "students"
      ]
    };
  },
  computed: {
    postUrl() {
      return (
        process.env.VUE_APP_SCHOOL_BACKEND_URL + "/api/excel/update-column"
      );
    },
    table() {
      let table = "";
      if (this.$route.query.table) {
        table = this.$route.query.table;
      }
      return table;
    }
  },
  watch: {},

  async mounted() {
    if (this.table !== "") {
      this.tableList = this.table;
      this.checkedTags = [];
      const table = await schoolApi.getTable({
        table: this.table
      });
      this.tags = table[this.table];
    }
  },

  methods: {
    beforeAvatarUpload() {
      this.loading = true;
    },
    handleAvatarSuccess(res) {
      this.$message({
        message: "資料更新成功！",
        type: "success"
      });
      this.loading = false;
    },
    addInput() {
      this.inputs.push("");
    },
    removeInput(index) {
      this.inputs.splice(index, 1);
    },
    verifyName(index) {
      let name = this.inputs[index];
    },
    async selectTable() {
      this.$router.replace({
        name: "SchoolsEdit",
        query: {
          table: this.tableList
        }
      });
    },
    async getTableFile() {
      let url = `${this.postUrl}?table=${this.tableList}`;
      this.checkedTags.forEach(element => {
        url += `&columns[]=${element}`;
      });
      window.open(url);
      // const file = await schoolApi.getTableFile({
      //   table: this.table,
      //   columns: this.checkedTags
      // });
      // if (file) {
      //   var fileName = this.table + ".xlsx";
      //   let blob = new Blob([file], {type:"application/vnd.ms-excel;charset=utf-8"});
      //   console.log(blob);
      //   let fileURL = window.URL.createObjectURL(blob);
      //   let fileLink = document.createElement("a");
      //   fileLink.href = fileURL;
      //   fileLink.setAttribute("download", fileName);
      //   document.body.appendChild(fileLink);
      //   fileLink.click();
      //   fileLink.remove();
      // } else {
      //   this.$message.error("對不起，您沒有權限下載本文件。");
      // }
    }
  }
};
</script>

<style scoped></style>
